@import '../../../node_modules/foundation-sites/scss/foundation';


/**
  SKELETON COMPONENTS
 */


.skeleton-ui-tile{
  width: 100%;
  position: relative;
  overflow: hidden;

  .skeleton-ui-image{
    aspect-ratio: 4/5;
    width: 100%;
    position: relative;

    background: linear-gradient(90deg, rgba(224, 224, 224, 0.8) 25%, rgba(240, 240, 240, 0.8) 50%, rgba(224, 224, 224, 0.8) 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.2s ease-in-out infinite;
    transition: opacity 0.5s ease;

    picture {
      height: 100%;
      width: 100%;
      display: block;

      img {
        width: 100%;
        height: 100%;
        opacity: 0;
        object-fit: cover;

        &.lazyloaded, &.preloaded{
          background: none;
          animation: none;
          opacity: 1;
        }
      }
    }
  }
}


/**
  SKELETON ANIMATIONS
 */


@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}